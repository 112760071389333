import React from 'react';
import { About, Description, Image } from '../styles';
import styled from 'styled-components';
//Images
import homeImg1 from '../img/home1.png';
import { titleAnim, fade, photoAnim } from '../animation';
import { motion } from 'framer-motion';
import Wave from './Wave';

const AboutSection = () => {
  return (
    <About>
      <Description>
        <div className="title">
          <Hide>
            <motion.h2 variants={titleAnim}>Hacemos</motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>
              tus <span>ideas</span> volverse
            </motion.h2>
          </Hide>
          <Hide>
            <motion.h2 variants={titleAnim}>realidad.</motion.h2>
          </Hide>
        </div>
        <motion.p variants={fade}>
          Contactame si piensas que puedo ayudarte en tu proyecto o idea, siempre estoy abierto a ofertas de trabajo.
        </motion.p>
        <a target="_blank" rel=" noopener noreferrer" href="https://www.linkedin.com/in/paul-dario-san-martin-martinez-565629203/">
        <motion.button variants={fade}>Contactame</motion.button>
        </a>
        
      </Description>
      <Image>
        <motion.img variants={photoAnim} src={homeImg1} alt="camera guy" />
      </Image>
      <Wave />
    </About>
  );
};

const Hide = styled.div`
  overflow: hidden;
`;

export default AboutSection;
