import React from 'react';
import { About } from '../styles';
import styled from 'styled-components';
import Toggle from './Toggle';
import { AnimateSharedLayout } from 'framer-motion';
//Scroll animation
import { reveal } from '../animation';
import { useScroll } from './useScroll';

const FaqSection = () => {
  const [element, controls] = useScroll();
  return (
    <Faq
      transition={{ duration: 0.5 }}
      ref={element}
      animate={controls}
      initial="hidden"
      variants={reveal}
    >
      <h2>
        ¿Alguna pregunta? <span>FAQ</span>
      </h2>

      <AnimateSharedLayout>
        <Toggle title="¿Que tecnologias dominas?">
          <div className="answer">
            <p>- Javascript</p>
            <p>- React.js</p>
            <p>- Vue.js</p>
            <p>- Next.js</p>
            <p>- Bases de datos como Firebase, MongoDB y SQL.</p>
            <p>- Node.js</p>
            <p>- PHP y Laravel</p>
          </div>
        </Toggle>
        <Toggle title="¿Que herramientas utilizas?">
          <div className="answer">
            <p>Aparte de las herramientas enfocadas a la programacion como editores de codigo tambien manejo distintos programas
              enfocados al diseño grafico como Photoshop, Illustrator, After Effects, Office y software de modelado en 3D como Blender.
            </p>
            
          </div>
        </Toggle>
        <Toggle title="¿Cuanto cobras?">
          <div className="answer">
            <p>Me adapto al presupuesto del cliente de acuerdo a los requerimientos y magnitud del proyecto, siempre podemos negociar antes de empezar
              con el proyecto para evitar problemas.
            </p>
            
          </div>
        </Toggle>
        <Toggle title="¿Trabajas en remoto, hibrido o en oficina?">
          <div className="answer">
            <p>Si el trabajo lo requiere no tengo problemas para cambiarme de residencia para cumplir con mis deberes.</p>
           
          </div>
        </Toggle>
      </AnimateSharedLayout>
    </Faq>
  );
};
const Faq = styled(About)`
  display: block;
  span {
    display: block;
    color: #23d997;
  }
  h2 {
    font-weight: lighter;
    padding-bottom: 4rem;
  }
  .faq-line {
    background: #cccccc;
    height: 0.2rem;
    margin: 2rem 0rem;
    width: 100%;
  }
  .answer {
    padding: 2rem 0rem;
    p {
      padding: 1rem 0rem;
    }
  }
`;

export default FaqSection;
