//Import Images
import festival from './img/1v1.png';
import blog from './img/2v2.png';

import blog2 from './img/2v1.png';
import festival2 from './img/1v2.png';


export const MovieState = () => {
  return [
    {
      title: 'Rock Festival',
      mainImg: festival,
      secondaryImg: festival2,
      url: '/work/festival',
      link: 'https://dnfest-dnpdx.netlify.app',
      awards: [
        {
          title: 'Javascript',
          description:
            '“Se uso javascript en la pagina en la parte de la galeria y el scroll.”',
        },
        {
          title: 'Bootstrap',
          description:
            '“Para la interfaz y estilos se uso la libreria de bootstrap por su facilidad de integracion”',
        },
        {
          title: 'HTML Y CSS',
          description:
            '“Todo lo demas es solo codigo de html y css, y para comprimir las imagenes y optimizarlas para web se uso gulp y sass.”',
        },
      ],
    },
    {
      title: 'Blog de Cafe',
      mainImg: blog,
      url: '/work/blog-cafe',
      secondaryImg: blog2,
      link: 'https://blog-cafe-dnpdx.netlify.app',
      awards: [
        {
          title: 'HTML Y Sass',
          description:
            '“La pagina esta construida solo con html y css sin usar ninguna libreria o scripts.”',
        },
        
      ],
    },
  
  ]; 
};
